import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
     Welcome to the official website of HG Krishna Bhakta Das.
    </div>
  );
}

export default App;
